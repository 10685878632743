export class LogPostRequest {
  currentPath: string
  additionalInfo: string
  stackTrace?: string

  constructor(path: string, info: string, stack?: string) {
    this.currentPath = path
    this.additionalInfo = info
    this.stackTrace = stack
  }
}
