import { Type } from 'class-transformer'
import { User } from '@/dtos/commons'
import type { ResolutionType, ResolutionState } from '@/constants/constants'

export const FILTER_TYPE = {
  MY_QUESTIONS: 1,
  OTHERS_QUESTIONS: 2,
} as const

type FilterType = typeof FILTER_TYPE[keyof typeof FILTER_TYPE]

export class QuestionsGetRequest {
  resolutionId: string
  filterType: FilterType
  skip = 0
  take: number

  constructor(resolutionId: string, filterType: FilterType, initialTake: number) {
    this.resolutionId = resolutionId
    this.filterType = filterType
    this.take = initialTake
  }
}

export class Answer {
  body!: string
  postedAt!: string
  @Type(() => User) user!: User
}

export class Question {
  questionId!: string
  body!: string
  postedAt!: string
  @Type(() => User) user!: User
  @Type(() => Answer) answer?: Answer
}

export class QuestionsGetResponse {
  resolutionId!: string
  resolutionType!: ResolutionType
  resolutionState!: ResolutionState
  @Type(() => Question)
  questions: Question[] = []
}
