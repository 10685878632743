import { APIClientBase } from './api-client-base'
import { QuestionsGetRequest, QuestionsGetResponse } from '@/dtos/resolutions/questions/get'
import { ResolutionReadsPostRequest } from '@/dtos/resolutions/reads/post'
import { QuestionDetailGetRequest, QuestionDetailGetResponse } from '@/dtos/resolutions/questions/get-detail'
import { QuestionsPostRequest } from '@/dtos/resolutions/questions/post'
import { QuestionsPutRequest } from '@/dtos/resolutions/questions/put'
import { QuestionsDeleteRequest } from '@/dtos/resolutions/questions/delete'
import { UnprocessedResolutionsSummaryGetResponse } from '@/dtos/resolutions/unprocessed/get'

class ResolutionsClient extends APIClientBase {
  constructor() {
    super('/resolutions')
  }

  // 決議一覧（サマリ）
  getUnprocessedResolutionsSummary() {
    return this.get('unprocessed/summary', undefined, UnprocessedResolutionsSummaryGetResponse)
  }

  postResolutionReads(req: ResolutionReadsPostRequest) {
    return this.post(`${req.resolutionId}/reads`, null)
  }

  // 質問
  getQuestions(req: QuestionsGetRequest) {
    const { resolutionId, ...query } = req
    return this.get(`/${resolutionId}/questions`, query, QuestionsGetResponse, false)
  }

  getQuestionDetail(req: QuestionDetailGetRequest) {
    return this.get(`${req.resolutionId}/questions/${req.questionId}`, null, QuestionDetailGetResponse)
  }

  postQuestions(req: QuestionsPostRequest): Promise<void> {
    return this.post(`${req.resolutionId}/questions`, req)
  }

  putQuestions(req: QuestionsPutRequest): Promise<void> {
    return this.put(`${req.resolutionId}/questions/${req.questionId}`, req)
  }

  deleteQuestions(req: QuestionsDeleteRequest): Promise<void> {
    return this.delete(`${req.resolutionId}/questions/${req.questionId}`)
  }
}

export const resolutionsClient = new ResolutionsClient()
