import { Type } from 'class-transformer'
import type { FunctionType } from '@/constants/constants'
import { Material } from '@/dtos/commons'

export class NotificationsGetRequest {
  skip = 0
  take: number

  constructor(initialTake: number) { this.take = initialTake }
}

export class Transition {
  title!: string
  transitionTo!: FunctionType
  transitionParams!: Record<string, string>
}

export class Notification {
  notificationId!: string
  isImportant!: boolean
  message!: string
  @Type(() => Transition) transition?: Transition
  @Type(() => Material) material?: Material
  notifiedAt!: string
  isUnread!: boolean
  needAnswerQuestionnaire?: boolean
}

export class NotificationsGetResponse {
  @Type(() => Notification)
  notifications!: Notification[]

  unreadCount!: number
}
