import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { store, isAlreadyLocalStored } from './index'

const storeName = 'localParamStorage'

/**
 * ローカルストレージに保持する画面パラメータ系の設定
 */
@Module({ store, dynamic: true, namespaced: true, name: storeName, preserveState: isAlreadyLocalStored })
class LocalParamStorageStore extends VuexModule {
  // メールアドレスを記憶する
  private _mailAddress: string | null = ''
  // LMログインIDを記憶する
  private _lmLoginId:string | null = ''

  get mailAddress(): string | null { return this._mailAddress }
  @Mutation private SET_MAIL_ADDRESS(mailAddress: string | null): void { this._mailAddress = mailAddress }
  @Action public putMailAddress(mailAddress: string): void { this.SET_MAIL_ADDRESS(mailAddress) }
  @Action public clearMailAddress(): void { this.SET_MAIL_ADDRESS(null) }

  get lmLoginId(): string | null { return this._lmLoginId }
  @Mutation private SET_LM_LOGIN_ID(lmLoginId: string | null): void { this._lmLoginId = lmLoginId }
  @Action public putLmLoginId(lmLoginId: string): void { this.SET_LM_LOGIN_ID(lmLoginId) }
  @Action public clearLmLoginId():void { this.SET_LM_LOGIN_ID(null) }
}

export const localParamStorageModule = getModule(LocalParamStorageStore)
