import { AuthState } from '@/constants/constants'
import { Type } from 'class-transformer'
import { OwnerProfileDto } from '../common'

export class SsoVerifyPostRequest {
  refreshToken: string

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken
  }
}

export const SSO_VERIFY_RESULT_CODE = {
  SUCCESS: 200,
  UNAUTHENTICATED: 401,
} as const
export type SsoVerifyResultCode = typeof SSO_VERIFY_RESULT_CODE[keyof typeof SSO_VERIFY_RESULT_CODE]

export class SsoVerifyPostResponse {
  resultCode!: SsoVerifyResultCode
  authState?: AuthState

  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
  accessToken?: string
}
