
import { Type } from 'class-transformer'
import { OwnerProfileDto, LoginResultCode } from '@/dtos/auth/common'

export class AuthAdminPostRequest {
  cognitoAccessToken: string
  buildingId?: string
  constructor(cognitoAccessToken: string, buildingId?: string) {
    this.cognitoAccessToken = cognitoAccessToken
    this.buildingId = buildingId
  }
}

export class AuthAdminPostResponse {
  resultCode!: LoginResultCode
  accessToken?: string
  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
}
