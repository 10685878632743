import { Type } from 'class-transformer'
import type { NotificationElementType, FunctionType } from '@/constants/constants'
import { Material } from '@/dtos/commons'

export class NotificationDetailGetRequest {
  notificationId!: string
  constructor(notificationId: string) { this.notificationId = notificationId }
}

export class FFNTransition {
  transitionTo!: FunctionType
  transitionParams!: Record<string, string>
}

export class BulletPoint {
  bulletPointId!: string
  bulletPointBody!: string
  hierarchyLevel!: number
}

export class FFNElement {
  freeFormatNotificationElementId!: string
  notificationElementType!: NotificationElementType
  body?: string
  @Type(() => FFNTransition)transition?: FFNTransition
  externalSiteUrl?: string
  @Type(() => BulletPoint)bulletPoints?: BulletPoint[]
  emailAddress?: string
  phoneNumber?: string
  @Type(() => Material)material?: Material
}

export class NotificationDetailGetResponse {
  notificationId!: string
  message!: string
  @Type(() => FFNElement)elements!: FFNElement[]
  @Type(() => Material)material?: Material
}
