import { DEVICE_TYPE, WEB_TYPE } from '@/constants/constants'
import type { DeviceType, WebType } from '@/constants/constants'

type WindowEventReceiver = {
  eventReceiver: Record<string, unknown>
}

type WindowWebkit = {
  webkit: {
    messageHandlers: Record<string, { postMessage: (arg?: string) => void }>
  }
}

// android webview利用時はグローバルな'android'オブジェクトが存在する
declare const android: Record<string, (arg?: string) => void>

class NativeBridge {
  private readonly _nativeEventReceiver: Record<string, (arg: string) => void> = {}
  private _deviceType: DeviceType = DEVICE_TYPE.WEB
  private _webType: WebType = WEB_TYPE.SAFARI
  private _isIos = false
  private _isAndroid = false

  constructor() {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.endsWith('/ios')) {
      this._deviceType = DEVICE_TYPE.IOS
    } else if (userAgent.endsWith('/android')) {
      this._deviceType = DEVICE_TYPE.ANDROID
    }

    // iosのブラウザの場合、safariが含まれているため
    // ios chrome＝crios、ios google search＝esa、ios edge＝edgiosを先に検出して
    // 純粋なsafariのみsafariの分岐に入れる
    if (userAgent.includes('crios')) {
      this._webType = WEB_TYPE.CRIOS
    } else if (userAgent.includes('esa')) {
      this._webType = WEB_TYPE.GSA
    } else if (userAgent.includes('edgios')) {
      this._webType = WEB_TYPE.EDGIOS
    } else if (userAgent.includes('safari')) {
      this._webType = WEB_TYPE.SAFARI
    }

    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      this._isIos = true
    }

    if (userAgent.includes('android')) {
      this._isAndroid = true
    }

    (window as Window & typeof globalThis & WindowEventReceiver).eventReceiver = {
      call: this.call.bind(this)
    }
  }

  get deviceType(): DeviceType { return this._deviceType }
  get isWeb(): boolean { return this._deviceType === DEVICE_TYPE.WEB }
  get isAndroid(): boolean { return this._deviceType === DEVICE_TYPE.ANDROID }
  get isIos(): boolean { return this._deviceType === DEVICE_TYPE.IOS }
  get isGsa(): boolean { return this._webType === WEB_TYPE.GSA }
  get isCrios(): boolean { return this._webType === WEB_TYPE.CRIOS }
  get isEdgios(): boolean { return this._webType === WEB_TYPE.EDGIOS }
  get isSafari(): boolean { return this._webType === WEB_TYPE.SAFARI }
  get isNativeApp(): boolean { return this._deviceType === DEVICE_TYPE.ANDROID || this._deviceType === DEVICE_TYPE.IOS }
  get isPdfViewerRequired(): boolean { return this.isNativeApp || this._isIos || this._isAndroid }

  isNativeEvent(eventName: string): boolean {
    switch (this._deviceType) {
      case DEVICE_TYPE.IOS: {
        const webkit = (window as Window & typeof globalThis & WindowWebkit).webkit
        return !!webkit.messageHandlers[eventName]
      }
      case DEVICE_TYPE.ANDROID: {
        if (android) {
          return !!android[eventName]
        }
        break
      }
    }
    return false
  }

  callNative(eventName: string, arg?: string, onWeb?: (arg?: string) => void) {
    switch (this._deviceType) {
      case DEVICE_TYPE.IOS: {
        const webkit = (window as Window & typeof globalThis & WindowWebkit).webkit
        if (webkit) webkit.messageHandlers[eventName].postMessage(arg)
        break
      }
      case DEVICE_TYPE.ANDROID: {
        // Androidはプリミティブ型しか渡せないことに注意、オブジェクトを渡す場合はJSONstringを経由する
        if (android) {
          if (arg !== undefined) android[eventName](arg)
          else android[eventName]()
        }
        break
      }
      default:
        if (onWeb) onWeb(arg)
    }
  }

  register(eventName: string, callback: (arg: string) => void) {
    this._nativeEventReceiver[eventName] = callback
  }

  call(eventName: string, arg: string) {
    if (this._nativeEventReceiver[eventName]) {
      this._nativeEventReceiver[eventName](arg)
      delete this._nativeEventReceiver[eventName]
    }
  }
}

export const nativeBridge = new NativeBridge()
