import 'reflect-metadata'
import './assets/style.css'
import 'vuetify/styles'

import { createApp, h } from 'vue'
import router from '@/router'
import { store, errorsModule, structureModule } from '@/stores'
import '@/stores/preserve-state'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import { required, max, min, email, integer } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { defineRule, configure, Form as ValidationForm, Field as ValidationField } from 'vee-validate'
import ja from '@vee-validate/i18n/dist/locale/ja.json'

import { logClient } from './clients/log-client'
import { LogPostRequest } from './dtos/log/post'
import { CSPReportPostRequest } from './dtos/log/csp/post'

import VueGtm from '@gtm-support/vue-gtm'

import awsconfig from '@/aws-exports'
import Auth from '@aws-amplify/auth'

const app = createApp({
  vuetify,
  render: () => h(App)
})
Auth.configure(awsconfig.Auth)

// Global-Component
app.component('ValidationForm', ValidationForm)
app.component('ValidationField', ValidationField)

const currencyFormatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'JPY'
})

const commaFormatter = new Intl.NumberFormat('en')
/**
 * 渡された金額をそのまま通貨表記（カンマ区切り）にする
 * 例：123456789 → ¥123,456,789
 */
app.config.globalProperties.$filters = {
  currency: (value: number) => {
    if (!value) {
      return '¥0'
    }
    return currencyFormatter.format(value)
  },

  /**
 * カンマ区切りにする
 * 例：123456789 → 123,456,789
 */
  comma: (value: number) => {
    if (value === undefined) { return '' }
    return commaFormatter.format(Math.round(value))
  },

  /**
 * 千円単位に変換（100円桁を四捨五入）した後にカンマ区切りにする
 * 例：123456789 → 123,457
 */
  commaByThousand: (value: number) => {
    if (value === undefined || value === null) { return '' }
    return commaFormatter.format(Math.round(value / 1000))
  }
}

// Vee-Validation settings
configure({
  generateMessage: localize({
    ja,
  }),
})
localize('ja')

defineRule('required', required)
defineRule('max', max)
defineRule('min', min)
// defineRule('alpha_num', alphaNum) ⇒ 「alpha_num」は日本語を許容するため英数字のみのバリデーションチェックは「alphanumeric」を使用すること
defineRule('email', email)
defineRule('integer', integer)

defineRule('admin_password', (inputValue:string) => {
  if (!inputValue || inputValue === '') return true
  const passwordPattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,}$/
  if (passwordPattern.test(inputValue)) return true
  return '大文字・小文字いずれも含む英字と数字の8文字以上の組み合わせで入力してください。'
})

defineRule('confirmation_password', (value:string, [targetPassword]:string) => {
  if (!value || value === '' || !targetPassword) return true
  if (value === targetPassword) return true
  return 'パスワードが一致しません'
})

/**
 * 英数字のみを許容する
 * vee-validateの標準ルール「alpha_num」では日本語を許容するため英数字のみのバリデーションチェックはこちらを使用すること
 */
defineRule('alphanumeric', (value: string, _params_, _field_) => {
  if (!value || value === '') return true
  const passwordPattern = /^[0-9a-zA-Z]*$/
  if (passwordPattern.test(value)) return true
  return `${_field_.name}は半角英数字のみで入力してください。`
})

const reportError = async(info: string | Record<string, unknown>, stackTrace?: string, err?: Error) => {
  // APIサーバからエラーが返却された場合なら何も処理しない
  if (errorsModule.hasErrors) return
  // navigation guard系のエラーも連携不要
  if (typeof info === 'object' && info._isRouter) return

  try {
    await logClient.postLogReport(new LogPostRequest(router.currentRoute.value.path, JSON.stringify(info), stackTrace))
    structureModule.updateSnackbarErrorMessage('システムエラーが発生しました')
  } catch (e) {
    console.error('failed to report error to server, cause: %o, original error: %o', e, err)
  }
}

// GA送信用
// 送信処理は、router.tsでライブラリーの読み込みができないため、
// App.vueでrouter.afterEachを使って実装している

app.use(VueGtm, {
  id: process.env.VUE_APP_GTM_TRACKING_ID,
  vueRouter: router,
})

// 例外処理
app.config.errorHandler = async function(err, vm, info) {
  if (err instanceof Error) await reportError(info, err.stack, err)
  throw err
}

const IGNORABLE_ERRORS: string[] = [
  'ResizeObserver loop limit exceeded',
]

// ↑の残りのエラーをキャッチ
window.addEventListener('error', async event => {
  // 無視できるエラーの場合、スナックバー表示とサーバーへの報告は行わない
  if (IGNORABLE_ERRORS.includes(event.message)) return
  await reportError(event.error)
})
window.addEventListener('unhandledrejection', async event => {
  await reportError(event.reason)
})
app.config.warnHandler = async function(msg, vm, trace) {
  console.warn(`${msg}, trace:${trace} `)
}

// CSP違反イベントをキャッチ
window.addEventListener('securitypolicyviolation', async event => {
  const req = new CSPReportPostRequest(event)
  await logClient.postCSPReport(req)
})

app.use(vuetify).use(router).use(store).mount('#app')
