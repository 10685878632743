<template>
  <svg
    class="sm__svg"
    height="24px"
    width="24px"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2C11.21 2 13 3.79 13 6C13 6.34533 12.9563 6.68041 12.8741 7H10C8.89543 7 8 7.89543 8 9V9.87411C6.2743 9.43034 5 7.86467 5 6C5 3.79 6.79 2 9 2ZM8 12.0517C5.189 12.3211 1 13.6418 1 16V18H8V12.0517Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8136 16.1H12V11H20V17.2567L18.8136 16.1ZM22 22V10.3C22 9.585 21.4 9 20.6667 9H11.3333C10.6 9 10 9.585 10 10.3V16.8C10 17.515 10.6 18.1 11.3333 18.1H18L22 22Z"
    />

  </svg>
</template>

<style scoped>
.sm__svg {
  fill:currentColor;
}
</style>
