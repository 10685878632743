import { Type } from 'class-transformer'
import { OwnerProfileDto } from '../../common'
import { IdentificationPostRequest } from '../post'
import type { IdentifyResultCode } from '../post'

export class AdditionalIdentificationPostRequest extends IdentificationPostRequest {
  condominiumCd: string
  roomNumber: string

  constructor(email: string, pass: string, authCode: string, condominiumCd: string, roomNumber: string) {
    super(email, pass, authCode)
    this.condominiumCd = condominiumCd
    this.roomNumber = roomNumber
  }
}

export class AdditionalIdentificationPostResponse {
  resultCode!:IdentifyResultCode
  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
  failedMessage?:string
  redirectTo?: string
  accessToken?: string
}
