import { DeviceType } from '@/constants/constants'

export class PostMyDeviceRequest {
  deviceType: DeviceType
  deviceToken: string

  constructor(type: DeviceType, token: string) {
    this.deviceType = type
    this.deviceToken = token
  }
}
