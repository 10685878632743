export class CSPReportPostRequest {
  blockedURI?: string
  columnNumber?: number
  documentURI?: string

  effectiveDirective?: string
  lineNumber?: number
  originalPolicy?: string

  referrer?: string
  sourceFile?: string
  statusCode?: number
  violatedDirective?: string

  constructor(event: SecurityPolicyViolationEvent) {
    this.blockedURI = event.blockedURI
    this.columnNumber = event.columnNumber
    this.documentURI = event.documentURI

    this.effectiveDirective = event.effectiveDirective
    this.lineNumber = event.lineNumber
    this.originalPolicy = event.originalPolicy

    this.referrer = event.referrer
    this.sourceFile = event.sourceFile
    this.statusCode = event.statusCode
    this.violatedDirective = event.violatedDirective
  }
}
