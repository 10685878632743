import { LOGIN_TYPE } from '@/constants/constants'
import { authModule } from '@/stores'

/**
 * *.tsファイル用で使うことを想定した機能群（Mixinsではない）
 * 画面（**Page.vue）で使いたい場合は当ファイルではなく @/mixins/current-user-manager.ts を使うこと
 */
export const isNormalLogin = () : boolean => { return authModule.currentLoginType === LOGIN_TYPE.FROM_OWNER_APPS.NORMAL }
export const isDummyLogin = () : boolean => { return authModule.currentLoginType === LOGIN_TYPE.FROM_OWNER_APPS.DUMMY }
export const isSimulateLogin = () : boolean => { return authModule.currentLoginType === LOGIN_TYPE.FROM_ADMIN_APPS.SIMULATE }
export const isAdmin = (): boolean => { return isDummyLogin() || isSimulateLogin() }
