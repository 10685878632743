import { APIClientBase } from './api-client-base'
import { MyBuildingsGetResponse } from '@/dtos/my/building/get'

class MyBuildingsClient extends APIClientBase {
  constructor() {
    super('/my/buildings')
  }

  getMyBuildings(): Promise<MyBuildingsGetResponse> {
    return this.get('', null, MyBuildingsGetResponse)
  }
}

export const myBuildingsClient = new MyBuildingsClient()
