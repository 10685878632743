import { h } from 'vue'
import { createVuetify } from 'vuetify'
import type { IconAliases, IconSet, ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import MaterialIcon from '@/components/atoms/icons/MaterialIcon.vue'
import SmChatIcon from '@/components/atoms/icons/SmChatIcon.vue'
import SmQuestionnaireIcon from '@/components/atoms/icons/SmQuestionnaireIcon.vue'
import SmWarningIcon from '@/components/atoms/icons/SmWarningIcon.vue'

const lightCustomTheme:ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#00A2D5', // Surface/MainColor, Object/MainColor
    secondary: '#0073A3', // Surface/MainDarkColor, Text/MainDarkColor, Object/MainDarkColor, Focus/Stroke
    accent: '#FFEF51', // Surface/AccentColor
    info: '#000000', // figmaのカラー指定との対応が判明次第修正？
    warning: '#B22D12', // Surface/CautionColor, Object/Caution, Stroke/Caution
    success: '#0073A3',
    error: '#B22D12',
    // custom
    reflection: '#C55200', // Surface/Reflection, Object/Reflection, Expressive/OrangeDark
    caution: '#B22D12',
    background: '#F3F5ED'
  }
}

const customIconSets: IconSet = {
  component: (iconProp) => {
    return h(MaterialIcon, { name: iconProp.icon })
  },
}

const vuetify = createVuetify({
  components,
  directives,
  display: {
    thresholds: {
      md: 1024,
    },
    mobileBreakpoint: 'md'
  },
  icons: {
    defaultSet: 'customIconSets',
    aliases: {
      sm_chat: SmChatIcon,
      sm_questionnaire: SmQuestionnaireIcon,
      sm_warning: SmWarningIcon,
      ...missingMaterialIcons([ // テーマを適用するアイコンはここに追加する（alphabet順）テーマを適用するアイコンはここに追加する（alphabet順）
        'add_reaction',
        'archive',
        'arrow_back',
        'arrow_circle_down',
        'arrow_drop_down',
        'arrow_forward_ios',
        'check_box',
        'check_box_outline_blank',
        'check_circle_outline',
        'chevron_right',
        'close',
        'content_paste',
        'dehaze',
        'delete_outline',
        'description',
        'east',
        'edit',
        'emoji_objects',
        'error_outline',
        'expand_less',
        'expand_more',
        'file_download',
        'filter_list',
        'flag',
        'groups',
        'how_to_vote',
        'indeterminate_check_box',
        'insert_chart',
        'insert_invitation',
        'notifications',
        'open_in_new',
        'photo',
        'push_pin',
        'question_answer',
        'radio_button_checked',
        'radio_button_unchecked',
        'receipt_long',
        'thumb_up',
        'today',
        'visibility',
        'visibility_off',
      ])
    },
    sets: {
      customIconSets
    },
  },
  theme: {
    defaultTheme: 'lightCustomTheme',
    themes: {
      lightCustomTheme
    },
    cspNonce: 'dQw4w9WgXcQ'
  }
})

// https://vuetifyjs.com/en/features/icons/#missing-material-icons
function missingMaterialIcons(ids:string[]) {
  const icons: Partial<IconAliases> = {}
  for (const id of ids) {
    for (const suffix of ['round', 'outlined', 'filled']) { // 使用するテーマはここに追加する
      const name = `${id}_${suffix}`
      icons[name] = `${id}_${suffix}`
    }
  }
  return icons
}
export default vuetify
