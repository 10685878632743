<template>
  <div id="app">
    <v-app :class="specifiedVAppCss">
      <sm-system-bar
        v-if="!this.$vuetify.display.mobile && systemBarVisible"
        :first-btn="systemBarFirstBtn"
        :second-btn="drawerVisible ? { icon: '$notifications_outlined', text: 'お知らせ' } : undefined"
        :second-badge-visible="notificationBadgeVisible"
        @click-first-btn="goToConsultations"
        @click-second-btn="goToNotifications"
        :class="{'d-print-none' : isPrintView}"
      />
      <sm-drawer
        v-if="drawerVisible"
        :items="navigations"
        :current="currentNavigation"
        @click-item="transTo"
        :class="{'d-print-none' : isPrintView}"
      />
      <div
        v-if="appBar.visible"
        :class="{'d-print-none' : isPrintView}"
      >
        <sm-app-bar
          v-if="drawerVisible"
          app
          v-model="selectedTab"
          :label="appBar.title"
          :left-icon="appBar.leftIcon"
          @click-left-icon="$router.back()"
          :is-extension-visible="appBar.isExtensionVisible"
          @click-extension-btn="onClickExtensionBtn"
          :class="{'d-print-none':isPrintView}"
        />
        <sm-app-bar
          v-else
          app
          v-model="selectedTab"
          :label="appBar.title"
          :left-icon="appBar.leftIcon"
          @click-left-icon="$router.back()"
          :icon-visible="appBar.rightIconVisible"
          :right-first-icon="!isConsultation ? undefined : '$sm_chat'"
          right-second-icon="$notifications_outlined"
          :right-second-badge-visible="notificationBadgeVisible"
          @click-right-first-icon="goToConsultations"
          @click-right-second-icon="goToNotifications"
          :is-extension-visible="appBar.isExtensionVisible"
          @click-extension-btn="onClickExtensionBtn"
          :class="{'d-print-none':isPrintView}"
        />
      </div>
      <v-main
        :class="[
          smMainClassDecider,
          applyBottomMargin ? 'sm__main-bottom-margin' : '',
        ]"
      >
        <sm-progress-overlay :show-overlay="showOverlay" />
        <div
          v-if="globalErrors.length > 0 && globalErrorVisible"
          class="pa-4 sm__background-white"
        >
          <sm-text
            :text="globalErrors.join('')"
            color="caution"
          />
        </div>
        <router-view />
      </v-main>

      <sm-dialog
        :model-value="showReLoginDialog"
        title-text="再ログインが必要です"
        body-text="一定時間が経過したため、自動でログアウトされました。"
        first-btn-label="ログイン画面へ"
        @click-first-btn="forceRelogin"
      />

      <sm-dialog
        :model-value="showSimulateReLoginDialog"
        title-text="再ログインが必要です"
        :body-text="'一定時間が経過したため、自動でログアウトされました。\n画面を閉じて、管理者アプリから再度「利用中画面を見る」操作を行ってください。'"
      />

      <sm-bottom-navigation
        v-if="bottomNavigationVisible"
        :items="navigations"
        :current="currentNavigation"
        @click-item="transTo"
        :class="{'d-print-none':isPrintView}"
      />
      <sm-snackbar
        v-if="displaySnackbar"
        v-model="displaySnackbar"
        :message="snackbar.message"
        :caution="snackbar.caution"
      />
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator'
import { defineAsyncComponent } from 'vue'
import { CurrentUserManager } from '@/mixins/current-user-manager'
import router from '@/router'
import { staticRoutes } from '@/routes'

import { AppBar, NAVIGATIONS, SnackBar, structureModule, analyticsModule, authModule, cognitoModule, errorsModule, myCurrentBuildingModule, paramStorageModule, resolutionsModule, notificationsModule } from '@/stores'
import { NavigationItems } from '@/dtos/navigation'
import type { NavigationId } from '@/stores'
import { sendPageViewGtm } from './libs/analytics-adapter'
import { GA_ANONYMOUS, REPAIR_PLAN_TYPES } from './constants/constants'

@Component({
  components: {
    SmText: defineAsyncComponent(() => import('@/components/atoms/SmText.vue')),

    SmAppBar: defineAsyncComponent(() => import('@/components/organisms/SmAppBar.vue')),
    SmBottomNavigation: defineAsyncComponent(() => import('@/components/organisms/SmBottomNavigation.vue')),
    SmDrawer: defineAsyncComponent(() => import('@/components/organisms/SmDrawer.vue')),
    SmDialog: defineAsyncComponent(() => import('@/components/organisms/SmDialog.vue')),
    SmProgressOverlay: defineAsyncComponent(() => import('@/components/organisms/SmProgressOverlay.vue')),
    SmSnackbar: defineAsyncComponent(() => import('@/components/organisms/SmSnackbar.vue')),
    SmSystemBar: defineAsyncComponent(() => import('@/components/organisms/SmSystemBar.vue')),
  }
})
export default class App extends mixins(CurrentUserManager) {
  get specifiedVAppCss(): string { return structureModule.specifiedVAppCss }
  get appBar(): AppBar { return structureModule.appBar }
  get systemBarVisible(): boolean { return structureModule.systemBarVisible }

  get currentNavigation(): NavigationId { return structureModule.currentNavigation }

  get showReLoginDialog(): boolean { return structureModule.reLoginDialogVisible }

  get showSimulateReLoginDialog(): boolean { return structureModule.simulateReLoginDialogVisible }

  private get systemBarFirstBtn(): { icon: string, text: string } | undefined {
    if (this.drawerVisible && this.isConsultation) return { icon: '$sm_chat', text: '相談・連絡' }
    return undefined
  }

  private get navigations(): NavigationItems {
    const navigations = Object.assign({}, NAVIGATIONS)
    navigations.decisionProcesses.badgeVisible = resolutionsModule.unprocessedResolutionBadgeVisible
    return navigations
  }

  private get notificationBadgeVisible():boolean {
    return notificationsModule.notificationBadgeVisible
  }

  private get isConsultation():boolean {
    return myCurrentBuildingModule.availabilities.isConsultation
  }

  isNavOpen = null

  private get navigationVisible():boolean {
    return structureModule.navigationVisible
  }

  private get bottomNavigationVisible():boolean {
    return this.navigationVisible && this.$vuetify.display.mobile
  }

  private get drawerVisible():boolean {
    return this.navigationVisible && !this.$vuetify.display.mobile
  }

  private get applyBottomMargin():boolean {
    return structureModule.applyBottomMargin
  }

  get selectedTab():string { return structureModule.appBar.selectedTab }
  set selectedTab(newValue:string) { structureModule.setSelectExpansionTab(newValue) }

  get showOverlay(): boolean { return structureModule.showOverlay }

  get globalErrors(): string[] { return errorsModule.globalErrors }

  get globalErrorVisible(): boolean { return structureModule.globalErrorVisible }

  get snackbar(): SnackBar {
    return structureModule.snackbar
  }

  get isPrintView():boolean {
    return structureModule.isPrintView
  }

  get smMainClassDecider():string {
    return this.isPrintView ? 'sm__main-print' : (this.drawerVisible ? 'sm__main' : 'sm__main-mobile')
  }

  get displaySnackbar(): boolean { return this.snackbar.display }
  set displaySnackbar(newValue: boolean) { structureModule.closeSnackbar() }

  async forceRelogin(): Promise<void> {
    structureModule.closeReLoginDialog()
    if (this.$isDummyLogin) {
      await cognitoModule.signOut() // LMログイン時はCognitoのセッションもクリアする
      this.$router.push({ name: staticRoutes.lmLogin.name })
    } else {
      await authModule.postLogout()
      this.$router.push({ name: staticRoutes.login.name })
    }
  }

  async goToConsultations(): Promise<void> {
    paramStorageModule.deleteAll()
    await Promise.all([
      notificationsModule.fetchNotificationBadgeVisible(),
      resolutionsModule.fetchUnprocessedResolutions()
    ])
    this.pushExceptSamePage(staticRoutes.consultations.name)
  }

  async goToNotifications(): Promise<void> {
    paramStorageModule.deleteAll()
    await resolutionsModule.fetchUnprocessedResolutions()
    this.pushExceptSamePage(staticRoutes.notifications.name)
  }

  onClickExtensionBtn(): void {
    const repairPlanType = myCurrentBuildingModule.availabilities.repairPlanType
    if (repairPlanType === REPAIR_PLAN_TYPES.REPAIR_PLAN) {
      this.$router.push({ name: staticRoutes.repairPlanUpdates.name })
    } else if (repairPlanType === REPAIR_PLAN_TYPES.SIMPLE_REPAIR_PLAN) {
      this.$router.push({ name: staticRoutes.simpleRepairPlanUpdates.name })
    }
  }

  async transTo(navigationId: NavigationId): Promise<void> {
    paramStorageModule.deleteAll()

    // カレントの制御はrouterで実施している
    switch (navigationId) {
      case NAVIGATIONS.decisionProcesses.id:
        await Promise.all([
          notificationsModule.fetchNotificationBadgeVisible(),
          resolutionsModule.fetchUnprocessedResolutionsNow()
        ])
        this.pushExceptSamePage(staticRoutes.decisionProcesses.name)
        break
      case NAVIGATIONS.menu.id:
        await Promise.all([
          notificationsModule.fetchNotificationBadgeVisible(),
          resolutionsModule.fetchUnprocessedResolutions()
        ])
        this.pushExceptSamePage(staticRoutes.menu.name)
        break
    }
  }

  pushExceptSamePage(routeName:string):void {
    if (this.$route.name !== routeName) this.$router.push({ name: routeName })
  }

  mounted():void {
    // GA送信
    router.afterEach((to) => {
      // ExternalLoginアクセス時は、プロパティ設定を待ってからGAを送信するためスキップ
      if (to.name === staticRoutes.externalLogin.name) return
      sendPageViewGtm(this.$gtm, to.path, to.name?.toString() ?? GA_ANONYMOUS)

      // リンク押下時、最終遷移先に辿り着いた後に、「そこから先の動き」を通知から分離するために、通知関連の情報を消す
      // それまでの遷移中は消さないようにする
      // 遷移経路は以下の通り(カッコ内は認証が切れていた場合に遷移)
      //  landing → external-login → FLP → landing → external-completion → （login → FLP → landing → login-completion →）decision-processes → 指定画面
      const gaPropertyRetentionPages = [
        staticRoutes.landing.name,
        staticRoutes.externalLoginCompletion.name,
        staticRoutes.login.name,
        staticRoutes.loginCompletion.name,
        staticRoutes.decisionProcesses.name
      ]
      if (to.name && gaPropertyRetentionPages.includes(to.name.toString())) return
      analyticsModule.clearAnalyticsProperties()
    })
  }
}
</script>
