export const join = (...paths: string[]): string => {
  let path = ''
  for (const p of paths) {
    if (!p) continue
    if (!p.startsWith('/') && path.length > 0) path += '/'

    path += p

    if (path.endsWith('/')) path = path.substr(0, path.length - 1)
  }
  return path
}
