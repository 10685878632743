export class QuestionDetailGetRequest {
  resolutionId!: string
  questionId!: string
}

export class QuestionDetailGetResponse {
  questionId!: string
  body!: string
  version!: number
}
