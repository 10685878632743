import { Type } from 'class-transformer'
import { UserIcon } from '../static-assets/user-icons/get'

export const LOGIN_RESULT_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
} as const
export type LoginResultCode = typeof LOGIN_RESULT_CODE[keyof typeof LOGIN_RESULT_CODE]

export class OwnerProfileDto {
  userId!:string
  @Type(() => UserIcon) icon?: UserIcon
  userName!:string
  roomNumber!:string
  emailAddress!:string
  adminId?: string
  alternativeLoginId?: string
}
