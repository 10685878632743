import { Type } from 'class-transformer'
import { RepairPlanType } from '@/constants/constants'

export class BuildingBasics {
  buildingId!: string
  condominiumCd!: string
  ridgeCd!: string
  buildingName!: string
  ownerCount!: number
  isTrialTargetBuilding!: boolean
}

export class Requirements {
  gmRequirement!: string
  onlineRequirement!: string
  agreementRequirement!: string
}

export class Accountings {
  accountingMonth!: number
  firstPeriodEndYear!: number
  smIntroducedPeriod!: number
}

export class AvailabilitiesSutekiNet {
  facilityReservation!: boolean
}

export class Availabilities {
  repairPlan!: boolean
  repairPlanType!: RepairPlanType
  @Type(() => AvailabilitiesSutekiNet) sutekiNet = new AvailabilitiesSutekiNet()
  isConsultation!: boolean
}

export class GetMyCurrentBuildingResponse {
  @Type(() => BuildingBasics) basics = new BuildingBasics()
  @Type(() => Requirements) requirements = new Requirements()
  @Type(() => Accountings) accountings = new Accountings()
  @Type(() => Availabilities) availabilities = new Availabilities()
}
