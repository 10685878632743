import { Component } from 'vue'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { RouteMetaData, StaticRoute, staticRoutes, staticRoutesLocal } from '@/routes'
import { AUTH_STATE } from '@/constants/constants'
import { structureModule, errorsModule, authModule } from '@/stores'

interface IRouteRecordRawBase {
  name: string
  path: string
  meta?: RouteMetaData
  alias?: string
  component?: Component
  prop: boolean | Record<string, unknown> | ((route: RouteLocationNormalized) => unknown)
  children: IRouteRecordRawBase[] // 未使用だがchildrenというkeyがないとvue-router側がtype違いでエラーと判断されてしまうため
}

class RouteRecordRawBase {
  name!: string
  path!: string
  meta?: RouteMetaData
  alias?: string
  component?: Component
  props!: boolean | Record<string, unknown> | ((route: RouteLocationNormalized) => unknown)
  children!: IRouteRecordRawBase[] // 未使用だがchildrenというkeyがないとvue-router側がtype違いでエラーと判断されてしまうため

  constructor(name?: string, path?: string, props?: boolean | Record<string, unknown> | ((route: RouteLocationNormalized) => unknown), children?: IRouteRecordRawBase[]) {
    this.name = name ?? ''
    this.path = path ?? ''
    this.props = props ?? {}
    this.children = children ?? [] // 未使用
  }
}
const configBase = (r: StaticRoute): RouteRecordRawBase => {
  if (!r.alias) return { name: r.name, path: r.path, meta: r.meta, props: r.props ?? true, children: [] } // aliasが無い場合、aliasというkey無しでオブジェクトを返さないとvue-router側でエラーとなる
  return { name: r.name, path: r.path, alias: r.alias, meta: r.meta, props: r.props ?? true, children: [] }
}

const routeRecordRaws = formatRouteRecordRaws()

// 環境変数でサンプル系ページへの遷移可否を制御する
// 環境変数はstringのためboolで判定する
if (process.env.VUE_APP_SHOW_SAMPLEPAGE === 'true') {
  const localRecordRaws = formatRouteRecordRawsLocal()
  routeRecordRaws.splice(routeRecordRaws.findIndex(c => c.path === '/.*'), 0, ...localRecordRaws)
}

const router = createRouter({
  routes: routeRecordRaws,
  history: createWebHistory(),
  scrollBehavior(to) {
    // 相談連絡のみ画面下部に固定したい為、スクロールを発火させない
    if (to.name === staticRoutes.consultations.name) return
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 500)
    })
  },
})

router.beforeEach((to, from, next) => {
  const toMeta = new RouteMetaData()
  Object.assign(toMeta, to.meta)

  // 遷移先は、以下のいずれかでなければならず、でなければ遷移を停止or未ログインであればランディング画面へ遷移させる
  // - 未ログインでも閲覧できるページ
  // - 現在の認証状態と、要求する認証状態とが合致するページ
  if (toMeta.necessaryAuthState !== AUTH_STATE.ANONYMOUS && authModule.currentAuthState !== toMeta.necessaryAuthState) {
    if (authModule.currentAuthState === AUTH_STATE.ANONYMOUS) {
      // 未ログイン状態で遷移しようとした画面がLM用ログイン導線に含まれる場合はLM用ランディング画面へ遷移させる
      if (toMeta.necessaryAuthState === AUTH_STATE.HAVE_TO_MFA || toMeta.necessaryAuthState === AUTH_STATE.HAVE_TO_CAUTION || toMeta.necessaryAuthState === AUTH_STATE.HAVE_TO_SELECT_BUILDING) {
        next({ name: staticRoutes.lmLanding.name })
      } else {
        next({ name: staticRoutes.landing.name })
      }
    } else {
      structureModule.updateSnackbarErrorMessage('そのページへの遷移は認められません')
      next(false)
    }
    return
  }

  if (errorsModule.hasErrors && !toMeta.keepGlobalError) errorsModule.clearErrors()

  // 全ページの共通構造の制御
  structureModule.specifyVAppCss(toMeta.specifiedVAppCss)
  structureModule.changeCurrentNavigation(toMeta.navigationGroup)

  if (toMeta.hideAppBar) structureModule.deleteAppBar()
  else structureModule.updateAppBar({})

  if (toMeta.hideSystemBar) structureModule.hideSystemBar()
  else structureModule.showSystemBar()

  if (toMeta.hideNavigation) structureModule.hideNavigation()
  else structureModule.showNavigation()

  if (toMeta.removeBottomMargin) structureModule.hideMarginBottom()
  else structureModule.showMarginBottom()

  if (toMeta.showPrintView) structureModule.showPrintView()
  else structureModule.hidePrintView()

  if (toMeta.keepGlobalError) structureModule.hideGlobalErrorMessage()
  else structureModule.showGlobalError()

  next()
})

export default router

// ------------------------------------------------------------------------------------------------
// initialization functions
// ------------------------------------------------------------------------------------------------
function formatRouteRecordRaws(): RouteRecordRaw[] {
  return Object.values(staticRoutes).map(_route => {
    const config = configBase(_route)

    switch (_route.name) {
      case staticRoutes.landing.name:
        config.component = () => import('@/pages/auth/LandingPage.vue'); break
      case staticRoutes.login.name:
        config.component = () => import('@/pages/auth/LoginPage.vue'); break
      case staticRoutes.loginCompletion.name:
        config.component = () => import('@/pages/auth/LoginCompletionPage.vue'); break
      case staticRoutes.externalLogin.name:
        config.component = () => import('@/pages/auth/ExternalLoginPage.vue'); break
      case staticRoutes.externalLoginCompletion.name:
        config.component = () => import('@/pages/auth/ExternalLoginCompletionPage.vue'); break
      case staticRoutes.logoutCompletion.name:
        config.component = () => import('@/pages/auth/LogoutCompletionPage.vue'); break

      case staticRoutes.lmLanding.name:
        config.component = () => import('@/pages/auth/LMLandingPage.vue'); break
      case staticRoutes.lmLogin.name:
        config.component = () => import('@/pages/auth/LMLoginPage.vue'); break
      case staticRoutes.lmPassword.name:
        config.component = () => import('@/pages/auth/LMPasswordPage.vue'); break
      case staticRoutes.lmAuthenticationKey.name:
        config.component = () => import('@/pages/auth/LMAuthenticationKeyInputPage.vue'); break
      case staticRoutes.lmCaution.name:
        config.component = () => import('@/pages/auth/LMCautionPage.vue'); break
      case staticRoutes.selectBuilding.name:
        config.component = () => import('@/pages/auth/SelectBuildingPage.vue'); break

      case staticRoutes.simulateLoginCompletion.name:
        config.component = () => import('@/pages/auth/SimulateLoginCompletionPage.vue'); break

      case staticRoutes.identification.name:
        config.component = () => import('@/pages/auth/IdentificationPage.vue'); break
      case staticRoutes.additionalIdentification.name:
        config.component = () => import('@/pages/auth/AdditionalIdentificationPage.vue'); break
      case staticRoutes.onBoarding.name:
        config.component = () => import('@/pages/descriptions/OnBoardingPage.vue'); break

      case staticRoutes.initialization.name:
        config.component = () => import('@/pages/accounts/InitializationPage.vue'); break
      case staticRoutes.contactAnonymous.name:
        config.component = () => import('@/pages/contact/ContactAnonymousPage.vue'); break
      case staticRoutes.account.name:
        config.component = () => import('@/pages/accounts/AccountPage.vue'); break

      case staticRoutes.menu.name:
        config.component = () => import('@/pages/menu/MenuPage.vue'); break

      case staticRoutes.ownerIdeaDetail.name:
        config.component = () => import('@/pages/idea/OwnerIdeaDetailPage.vue'); break
      case staticRoutes.adminIdeaDetail.name:
        config.component = () => import('@/pages/idea/AdminIdeaDetailPage.vue'); break
      case staticRoutes.ideaUpdatesList.name:
        config.component = () => import('@/pages/idea-updates/IdeaUpdatesListPage.vue'); break

      case staticRoutes.gmResolutionDetail.name:
        config.component = () => import('@/pages/resolutions/GMResolutionDetailPage.vue'); break
      case staticRoutes.onlineResolutionDetail.name:
        config.component = () => import('@/pages/resolutions/OnlineResolutionDetailPage.vue'); break
      case staticRoutes.gmResolutionQuestionsList.name:
      case staticRoutes.onlineResolutionQuestionsList.name:
        config.component = () => import('@/pages/resolutions/QuestionListPage.vue'); break

      case staticRoutes.questionnairesList.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairesListPage.vue'); break
      case staticRoutes.questionnaireAnswerConfirmation.name:
        config.component = () => import('@/pages/questionnaires/QuestionnaireAnswerConfirmationPage.vue'); break
      case staticRoutes.questionnairesReportDetail.name:
        config.component = () => import('@/pages/questionnaires/QuestionnairesReportDetailPage.vue'); break
      case staticRoutes.archivedQuestionnairesList.name:
        config.component = () => import('@/pages/questionnaires/ArchivedQuestionnairesListPage.vue'); break
      case staticRoutes.questionnaireDetailTop.name:
        config.component = () => import('@/pages/questionnaires/QuestionnaireDetailTopPage.vue'); break

      case staticRoutes.repairPlan.name:
        config.component = () => import('@/pages/repair-plan/RepairPlanPage.vue'); break
      case staticRoutes.repairPlanUpdates.name:
        config.component = () => import('@/pages/repair-plan-updates/RepairPlanUpdatesPage.vue'); break
      case staticRoutes.repairPlanUpdateDetail.name:
        config.component = () => import('@/pages/repair-plan-updates/RepairPlanUpdateDetailPage.vue'); break
      case staticRoutes.repairPlanPrint.name:
        config.component = () => import('@/pages/repair-plan/RepairPlanPrintPage.vue'); break
      case staticRoutes.repairPlanDetailPrint.name:
        config.component = () => import('@/pages/repair-plan/RepairPlanDetailPrintPage.vue'); break

      case staticRoutes.consultations.name:
        config.component = () => import('@/pages/consultations/ConsultationsPage.vue'); break
      case staticRoutes.notifications.name:
        config.component = () => import('@/pages/notifications/NotificationsPage.vue'); break
      case staticRoutes.notificationDetail.name:
        config.component = () => import('@/pages/notifications/NotificationDetailPage.vue'); break

      case staticRoutes.simpleRepairPlan.name:
        config.component = () => import('@/pages/simple-repair-plan/SimpleRepairPlanDetailPage.vue'); break
      case staticRoutes.simpleRepairPlanUpdates.name:
        config.component = () => import('@/pages/simple-repair-plan/updates/SimpleRepairPlanUpdatesPage.vue'); break
      case staticRoutes.simpleRepairPlanDetailPrintPage.name:
        config.component = () => import('@/pages/simple-repair-plan/SimpleRepairPlanDetailPrintPage.vue'); break
      case staticRoutes.simpleRepairPlanConstructionPrint.name:
        config.component = () => import('@/pages/simple-repair-plan/construction/SimpleRepairPlanConstructionPrintPage.vue'); break

      case staticRoutes.support.name:
        config.component = () => import('@/pages/support/SupportPage.vue'); break
      case staticRoutes.supportQa.name:
        config.component = () => import('@/pages/support/SupportQaPage.vue'); break
      case staticRoutes.contact.name:
        config.component = () => import('@/pages/contact/ContactPage.vue'); break

      case staticRoutes.archivedDecisionProcesses.name:
        config.component = () => import('@/pages/decision-processes/ArchivedDecisionProcessesListPage.vue'); break
      case staticRoutes.decisionProcesses.name:
        config.component = () => import('@/pages/decision-processes/DecisionProcessesListPage.vue'); break

      case staticRoutes.maintenance.name:
        config.component = () => import('@/pages/error/MaintenancePage.vue'); break

      case staticRoutes.initialRegistrationManual.name:
        config.component = () => import('@/pages/static-assets/InitialRegistrationManual.vue'); break

      case staticRoutes.notFound.name:
        config.component = () => import('@/pages/error/NotFoundPage.vue'); break

      case staticRoutes.deleted.name:
        config.component = () => import('@/pages/error/DeletedPage.vue'); break
      default: {
        // 開発時にエラー原因に気づきやすいようにするためのもの。単体テスト以降で通過想定なし
        throw new Error(`${_route.name} page component not defined`)
      }
    }

    return config
  })
}

function formatRouteRecordRawsLocal(): RouteRecordRaw[] {
  return Object.values(staticRoutesLocal).map(_route => {
    const config = configBase(_route)

    switch (_route.name) {
      case staticRoutesLocal.helloWorld.name:
        config.component = () => import('@/pages/HelloWorld.vue'); break
      default: {
        throw new Error(`${_route.name} page component not defined`)
      }
    }

    return config
  })
}
