import { Type } from 'class-transformer'
import { LoginPostRequest } from '@/dtos/auth/login/post'
import { OwnerProfileDto } from '../common'

export class IdentificationPostRequest extends LoginPostRequest {
  authCode: string

  constructor(email: string, pass: string, authCode: string) {
    super(email, pass)
    this.authCode = authCode
  }
}

export const IDENTIFY_RESULT_CODE = {
  SUCCESS: 200,
  FORBIDDEN: 403,
} as const
export type IdentifyResultCode = typeof IDENTIFY_RESULT_CODE[keyof typeof IDENTIFY_RESULT_CODE]

export class IdentificationPostResponse {
  resultCode!:IdentifyResultCode
  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
  failedMessage?:string

  redirectTo?: string
  accessToken?: string

  requiredAdditionalIdentification?: boolean
}
