import { APIClientBase } from './api-client-base'
import { LogPostRequest } from '@/dtos/log/post'
import { CSPReportPostRequest } from '@/dtos/log/csp/post'

class LogClient extends APIClientBase {
  private readonly _reportedStack: string[] = []

  constructor() {
    super('/log')
  }

  /**
   * クライアントエラーをサーバに報告する
   * 一定件数の過去の報告内容は履歴管理され、全く同一内容の場合は報告がスキップされる
   */
  async postLogReport(req: LogPostRequest): Promise<void> {
    const report = JSON.stringify(req)
    if (this._reportedStack.includes(report)) return Promise.resolve()

    await this.post('', req)

    if (this._reportedStack.length >= 5) this._reportedStack.pop()
    this._reportedStack.unshift(report)
  }

  /**
   * CSP違反レポートをサーバに報告する
   * 一定件数の過去の報告内容は履歴管理され、全く同一内容の場合は報告がスキップされる
   */
  async postCSPReport(req: CSPReportPostRequest): Promise<void> {
    const report = JSON.stringify(req)
    if (this._reportedStack.includes(report)) return Promise.resolve()

    await this.post('/csp', req)

    if (this._reportedStack.length >= 5) this._reportedStack.pop()
    this._reportedStack.unshift(report)
  }
}

export const logClient = new LogClient()
