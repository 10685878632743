import { ConsultationReactionsResponse } from '@/dtos/static-assets/reactions/get'
import { GetUserIconsResponse } from '@/dtos/static-assets/user-icons/get'
import { GetCommonPdfResponse } from '@/dtos/static-assets/get'
import { APIClientBase } from './api-client-base'

class StaticAssetsClient extends APIClientBase {
  constructor() {
    super('/static-assets')
  }

  async getUserIcons():Promise<GetUserIconsResponse> {
    return await this.get('user-icons', null, GetUserIconsResponse)
  }

  // リアクション一覧の取得
  getConsultationReactions(): Promise<ConsultationReactionsResponse> {
    return this.get('/reactions', '', ConsultationReactionsResponse, false)
  }

  getRegulationsPdf() {
    return this.get('/regulations', null, GetCommonPdfResponse)
  }

  getInitialRegistrationManualPdf() {
    return this.get('/initial-registration-manual', null, GetCommonPdfResponse)
  }
}

export const staticAssetsClient = new StaticAssetsClient()
