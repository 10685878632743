import { MaterialType, IdeaType } from '@/constants/constants'

/** APIのI/Fで共通に登場する戻り値の構造を定義する */

export class User {
  userId!: string
  iconImagePath!: string
  userName!: string
  roomNumber?: string
}

export class Material {
  materialId!: string
  materialUrl!: string
  materialDownloadUrl?: string
  materialType!: MaterialType
  originalFileName!: string
  caption?: string
}

export class BaseIdea {
  ideaId!:string
  ideaType!: IdeaType
}

export class Budget {
  budget!:number
  budgetLabel!:string
  expense!:number
  expenseLabel!:string
  spent?:number
  spentLabel?:string
  balanceLabel!:string
}

export class MaterialInput {
  materialId?: string
  caption?: string
}
