<template>
  <svg
    class="sm__svg"
    height="24px"
    width="24px"
  >
    <path
      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V21C3 22.1 3.9 23 5 23H19C20.1 23 21 22.1 21 21V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM19 21H5V5H7V8H17V5H19V21Z"
    />
    <path
      d="M8.26621 19.0813L5.99121 16.8063L6.80788 15.9896L8.26621 17.4333L10.8766 14.8229L11.6933 15.6542L8.26621 19.0813ZM8.26621 14.4146L5.99121 12.1396L6.80788 11.3229L8.26621 12.7667L10.8766 10.1562L11.6933 10.9875L8.26621 14.4146ZM12.612 17.9146V16.7479H18.0079V17.9146H12.612ZM12.612 13.2479V12.0812H18.0079V13.2479H12.612Z"
    />
  </svg>
</template>

<style scoped>
.sm__svg {
  fill:currentColor;
}
</style>
