import { Type } from 'class-transformer'

export class Reaction {
  assetId!: string
  assetPath!: string
}

export class ConsultationReactionsResponse {
  @Type(() => Reaction) reactions: Reaction[] = []
}
