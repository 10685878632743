import { Type } from 'class-transformer'
import type { LoginResultCode } from '@/dtos/auth/common'
import { OwnerProfileDto } from '@/dtos/auth/common'

export class SimulateLoginPostRequest {
  adminId: string
  oneTimeToken: string

  constructor(adminId: string, oneTimeToken: string) {
    this.adminId = adminId
    this.oneTimeToken = oneTimeToken
  }
}

export class SimulateLoginPostResponse {
  resultCode!: LoginResultCode
  accessToken?: string
  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
}
