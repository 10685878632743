import { APIClientBase } from './api-client-base'
import { NotificationsGetRequest, NotificationsGetResponse } from '@/dtos/notifications/get'
import { NotificationDetailGetRequest, NotificationDetailGetResponse } from '@/dtos/notifications/get-detail'
import { NotificationsReadPostRequest } from '@/dtos/notifications/post'

class NotificationsClient extends APIClientBase {
  constructor() {
    super('/notifications')
  }

  getNotifications(req: NotificationsGetRequest) {
    return this.get('', req, NotificationsGetResponse, false)
  }

  postNotificationsRead(req?: NotificationsReadPostRequest) {
    return this.post('read', req)
  }

  getNotificationDetail(req: NotificationDetailGetRequest) {
    return this.get(`/${req.notificationId}`, null, NotificationDetailGetResponse)
  }
}

export const notificationsClient = new NotificationsClient()
