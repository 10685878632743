import { Type } from 'class-transformer'

export class UserIcon {
  assetId!:string
  assetPath!:string
}

export class GetUserIconsResponse {
  @Type(() => UserIcon)
  icons!:UserIcon[]
}
