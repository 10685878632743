/**
 * バッジ更新のスキップ期間（ミリ秒）
 * 現在値：5分
 */
export const BADGE_SKIP_TIME = 5 * 60 * 1000

/**
 * バッジ更新をスキップするか判定
 * @param lastTime
 */
export function skipUpdate(lastTime:Date):boolean {
  return Date.now() - lastTime.getTime() <= BADGE_SKIP_TIME
}
