import { staticAssetsClient } from '@/clients/static-assets-client'
import { ConsultationReactionsResponse } from '@/dtos/static-assets/reactions/get'
import type { Reaction } from '@/dtos/static-assets/reactions/get'
import { UserIcon } from '@/dtos/static-assets/user-icons/get'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { GetCommonPdfResponse } from '@/dtos/static-assets/get'

@Module({ store, dynamic: true, namespaced: true, name: 'staticAssets', preserveState: isAlreadyInitialized })
class StaticAssetsStore extends VuexModule {
  private _userIcons:UserIcon[] = []

  public get userIcons():UserIcon[] {
    return this._userIcons
  }

  @Mutation
  private SET_USER_ICONS(items:UserIcon[]):void {
    this._userIcons = items
  }

  @Action
  public async fetchUserIcons():Promise<void> {
    const res = await staticAssetsClient.getUserIcons()
    this.SET_USER_ICONS(res.icons)
  }

  // リアクション一覧の取得
  private _reactions: Reaction[] = []

  get reactions(): Reaction[] { return this._reactions }

  @Mutation
  private SET_REACTION_GET(res: ConsultationReactionsResponse): void {
    this._reactions = res.reactions
  }

  @Action
  async fetchReactions(): Promise<void> {
    const res = await staticAssetsClient.getConsultationReactions()
    this.SET_REACTION_GET(res)
  }

  private _RegulationsPdfGet: GetCommonPdfResponse = new GetCommonPdfResponse()

  get RegulationsPdfGet(): GetCommonPdfResponse {
    return this._RegulationsPdfGet
  }

  @Mutation
  private SET_REGULATIONS_PDF_GET(res: GetCommonPdfResponse): void {
    this._RegulationsPdfGet = res
  }

  @Action
  async fetchRegulationsPdf(): Promise<void> {
    const res = await staticAssetsClient.getRegulationsPdf()
    this.SET_REGULATIONS_PDF_GET(res)
  }

  private _InitialRegistrationManualPdfGet: GetCommonPdfResponse = new GetCommonPdfResponse()

  get InitialRegistrationManualGet(): GetCommonPdfResponse {
    return this._InitialRegistrationManualPdfGet
  }

  @Mutation
  private SET_INITIAL_REGISTRATION_MANUAL_PDF_GET(res: GetCommonPdfResponse): void {
    this._InitialRegistrationManualPdfGet = res
  }

  @Action
  async fetchInitialRegistrationManualPdf(): Promise<void> {
    const res = await staticAssetsClient.getInitialRegistrationManualPdf()
    this.SET_INITIAL_REGISTRATION_MANUAL_PDF_GET(res)
  }
}

export const staticAssetsModule = getModule(StaticAssetsStore)
