import { GetMyCurrentBuildingResponse } from '@/dtos/my/building/current/get'
import { CurrentBuildingPostRequest } from '@/dtos/my/building/current/post'
import { APIClientBase } from './api-client-base'

class MyCurrentBuildingClient extends APIClientBase {
  constructor() {
    super('/my')
  }

  async getCurrentBuilding():Promise<GetMyCurrentBuildingResponse> {
    return await this.get('/buildings/current', null, GetMyCurrentBuildingResponse)
  }

  async postCurrentBuilding(req: CurrentBuildingPostRequest): Promise<void> {
    await this.post('/buildings/current', req)
  }
}

export const myCurrentBuildingClient = new MyCurrentBuildingClient()
