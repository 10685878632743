import { Type } from 'class-transformer'

export class Building {
  buildingId!:string
  condominiumCd!:string
  buildingName!:string
}

export class MyBuildingsGetResponse {
  @Type(() => Building)
  buildings: Building[] = []
}
